.dkstore {
  max-width: 750px;
  padding: 20px;
  margin: auto;
  background-color: #f9f9f9;


  .ajax-error-box {
    position: fixed;
    top: 40%;
    left: 50%;
    width: 30em;
    height: 18em;
    margin-top: -9em; /*set to a negative number 1/2 of your height*/
    margin-left: -15em; /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
    z-index: 999;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-size: 14px;
    border-radius: 7px;
  }

  .ajax-error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 998;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    opacity: 0.8;
  }
}
