.dkstore {
  .shopping-cart {
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    position: relative;

    .shopping-cart-header {
      font-weight: 600;
    }

    .lds-roller {
      position: absolute;
      top: 45%;
      left: 45%;
    }

    .shopping-cart-main {
      min-height: 18vh;
      padding-bottom: 10px;
    }

    .sum, .subtotal, .tax {
      font-weight: 600;
      width: 16%;
      text-align: right;
    }

    .sub-total {
      border-bottom: 5px double #eee;
      text-align: right;
    }

    .sub-total, .tax-total, .sum-total {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .shopping-cart-item, .shopping-cart-header {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      > div {
        padding: 10px 0;
      }
    }

    .shopping-cart-item {
      background-color: #f9f9f9;
      border-radius: 5px;
      margin-top: 10px;
    }

    .items {
      display: flex;
      flex-direction: column;
    }

    .product-price, .item-total {
      width: 16%;
    }

    .item-remove {
      width: 5%;
    }


    .product-image {
      display: flex;
      justify-content: center;
      width: 15%;

      img {
        max-width: 90%;
        max-height: 80px;
      }
    }

    .quantity {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      width: 10%;

      .form-control {
        // similar to the bootstrap :focus styling...


        &.validated-false {
          border-color: #e35c5c;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(227, 92, 92, 0.6);
        }

        &.validated-true {
          border-color: #70e0af;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(112, 224, 175, 0.6);
        }
      }

      .select-single {
        border: none !important;
        background-color: inherit !important;
        box-shadow: none !important;
        cursor: default !important;
        text-align: center !important;

        /* remove input arrows in Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* remove input arrows in firefox */
        -moz-appearance: textfield;
      }

    }

    .product-price, .item-total {
      text-align: right;
    }

    .empty-cart {
      text-align: center;
      padding-top: 20px;
    }

    .item-remove {
      width: 5%;
      text-align: center;
    }

    .quantity-widget {
      width: 100%;
    }

    .product-name {
      flex: 1;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: baseline;
      padding-bottom: 30px;
      gap: 10px;
      border-top: 1px solid #eee;
      padding-top: 10px;

      .sum, .subtotal, .mva {
        font-weight: 600;
      }

      .sub-total {
        border-bottom: 5px double #eee;
        text-align: right;
      }

    }

    .action-btns {
      display: flex;
      justify-content: space-between;


      .btn {
        min-width: 150px;
      }

      .submit-btn {
        max-width: 150px;
      }
    }

    .item-meta {
      margin: auto;
    }

    .header-buttons {
      display: flex;
      justify-content: space-between;


      .tax-wrapper {
        display: flex;
        gap: 5px;
      }

      .show-tax {
        margin: 3px 0 0 !important;
      }

      .clear-cart {
        //display: flex;
        //justify-content: flex-end;
        //padding-top: 10px;

        button {
          text-decoration: underline;
          border: none;
          background-color: inherit;

          :active {
            background-color: red;
          }
        }
      }
    }
  }

  .loading {
    opacity: 0.5;
  }
}
