.dkstore .lds-roller {
  width: 80px;
  height: 80px;
  z-index: 10;
}
.dkstore .lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.dkstore .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}
.dkstore .lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.dkstore .lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.dkstore .lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.dkstore .lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.dkstore .lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.dkstore .lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.dkstore .lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.dkstore .lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.dkstore .lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.dkstore .lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.dkstore .lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.dkstore .lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.dkstore .lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.dkstore .lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.dkstore .lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.dkstore .lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dkstore .shopping-cart {
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  position: relative;
}
.dkstore .shopping-cart .shopping-cart-header {
  font-weight: 600;
}
.dkstore .shopping-cart .lds-roller {
  position: absolute;
  top: 45%;
  left: 45%;
}
.dkstore .shopping-cart .shopping-cart-main {
  min-height: 18vh;
  padding-bottom: 10px;
}
.dkstore .shopping-cart .sum,
.dkstore .shopping-cart .subtotal,
.dkstore .shopping-cart .tax {
  font-weight: 600;
  width: 16%;
  text-align: right;
}
.dkstore .shopping-cart .sub-total {
  border-bottom: 5px double #eee;
  text-align: right;
}
.dkstore .shopping-cart .sub-total,
.dkstore .shopping-cart .tax-total,
.dkstore .shopping-cart .sum-total {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.dkstore .shopping-cart .shopping-cart-item,
.dkstore .shopping-cart .shopping-cart-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.dkstore .shopping-cart .shopping-cart-item > div,
.dkstore .shopping-cart .shopping-cart-header > div {
  padding: 10px 0;
}
.dkstore .shopping-cart .shopping-cart-item {
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 10px;
}
.dkstore .shopping-cart .items {
  display: flex;
  flex-direction: column;
}
.dkstore .shopping-cart .product-price,
.dkstore .shopping-cart .item-total {
  width: 16%;
}
.dkstore .shopping-cart .item-remove {
  width: 5%;
}
.dkstore .shopping-cart .product-image {
  display: flex;
  justify-content: center;
  width: 15%;
}
.dkstore .shopping-cart .product-image img {
  max-width: 90%;
  max-height: 80px;
}
.dkstore .shopping-cart .quantity {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 10%;
}
.dkstore .shopping-cart .quantity .form-control.validated-false {
  border-color: #e35c5c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(227, 92, 92, 0.6);
}
.dkstore .shopping-cart .quantity .form-control.validated-true {
  border-color: #70e0af;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(112, 224, 175, 0.6);
}
.dkstore .shopping-cart .quantity .select-single {
  border: none !important;
  background-color: inherit !important;
  box-shadow: none !important;
  cursor: default !important;
  text-align: center !important;
  /* remove input arrows in Chrome, Safari, Edge, Opera */
  /* remove input arrows in firefox */
  -moz-appearance: textfield;
}
.dkstore .shopping-cart .quantity .select-single input::-webkit-outer-spin-button,
.dkstore .shopping-cart .quantity .select-single input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dkstore .shopping-cart .product-price,
.dkstore .shopping-cart .item-total {
  text-align: right;
}
.dkstore .shopping-cart .empty-cart {
  text-align: center;
  padding-top: 20px;
}
.dkstore .shopping-cart .item-remove {
  width: 5%;
  text-align: center;
}
.dkstore .shopping-cart .quantity-widget {
  width: 100%;
}
.dkstore .shopping-cart .product-name {
  flex: 1;
}
.dkstore .shopping-cart .footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: baseline;
  padding-bottom: 30px;
  gap: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
}
.dkstore .shopping-cart .footer .sum,
.dkstore .shopping-cart .footer .subtotal,
.dkstore .shopping-cart .footer .mva {
  font-weight: 600;
}
.dkstore .shopping-cart .footer .sub-total {
  border-bottom: 5px double #eee;
  text-align: right;
}
.dkstore .shopping-cart .action-btns {
  display: flex;
  justify-content: space-between;
}
.dkstore .shopping-cart .action-btns .btn {
  min-width: 150px;
}
.dkstore .shopping-cart .action-btns .submit-btn {
  max-width: 150px;
}
.dkstore .shopping-cart .item-meta {
  margin: auto;
}
.dkstore .shopping-cart .header-buttons {
  display: flex;
  justify-content: space-between;
}
.dkstore .shopping-cart .header-buttons .tax-wrapper {
  display: flex;
  gap: 5px;
}
.dkstore .shopping-cart .header-buttons .show-tax {
  margin: 3px 0 0 !important;
}
.dkstore .shopping-cart .header-buttons .clear-cart button {
  text-decoration: underline;
  border: none;
  background-color: inherit;
}
.dkstore .shopping-cart .header-buttons .clear-cart button :active {
  background-color: red;
}
.dkstore .loading {
  opacity: 0.5;
}
.dkstore .cart-quantity {
  position: absolute;
  top: 0;
  right: 0;
  background-color: orange;
  height: 2em;
  width: 2em;
  text-align: center;
  border-radius: 50%;
  padding-top: 5px;
}
.dkstore .cart-redirect {
  color: black;
}
.dkstore .cart-redirect :hover {
  color: grey;
}
.dkstore .product-list {
  padding: 10px;
  border-radius: 5px;
  background-color: white;
}
.dkstore .product-list .tax-wrapper {
  display: flex;
  gap: 5px;
}
.dkstore .product-list .show-tax {
  margin: 3px 0 0!important;
}
.dkstore .product-list .product-list-header {
  font-weight: 600;
}
.dkstore .product-list .product-list-item,
.dkstore .product-list .product-list-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.dkstore .product-list .product-list-item > div,
.dkstore .product-list .product-list-header > div {
  padding: 10px 5px;
}
.dkstore .product-list .product-list-item {
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 15px;
  padding: 5px 0;
}
.dkstore .product-list .product-list-main {
  min-height: 20vh;
  padding-bottom: 50px;
}
.dkstore .product-list .item,
.dkstore .product-list .header-item {
  display: flex;
  align-items: center;
}
.dkstore .product-list .items {
  display: flex;
  flex-direction: column;
}
.dkstore .product-list .product-price {
  width: 15%;
  justify-content: flex-end;
}
.dkstore .product-list .product-image {
  display: flex;
  justify-content: center;
  width: 15%;
}
.dkstore .product-list .product-image img {
  max-width: 90%;
  max-height: 80px;
}
.dkstore .product-list .item-action {
  min-width: 30%;
  display: flex;
  justify-content: flex-end;
}
.dkstore .product-list .product-name {
  flex: 1;
}
.dkstore .product-list .action-btns {
  display: flex;
  justify-content: flex-end;
  padding: 10px 5px;
}
.dkstore .product-list .action-btns .submit-btn {
  min-width: 150px;
}
.dkstore .product-list .add-button {
  min-width: 150px;
  padding: 5px;
}
.dkstore .product-list .added {
  background-color: lightgreen;
}
.dkstore {
  max-width: 750px;
  padding: 20px;
  margin: auto;
  background-color: #f9f9f9;
}
.dkstore .ajax-error-box {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 30em;
  height: 18em;
  margin-top: -9em;
  /*set to a negative number 1/2 of your height*/
  margin-left: -15em;
  /*set to a negative number 1/2 of your width*/
  border: 1px solid #ccc;
  z-index: 999;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 14px;
  border-radius: 7px;
}
.dkstore .ajax-error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 998;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  opacity: 0.8;
}
.dkstore {
  border-radius: 10px;
}
.dkstore .confirmation {
  width: 100%;
  min-height: 40vh;
}
.dkstore .confirmation .order-text {
  padding: 20px 0;
}
.dkstore .confirmation .order-info {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 10px;
}
.dkstore .confirmation .order-info .order-info-table {
  padding-top: 20px;
}
.dkstore .confirmation .order-info .order-info-header,
.dkstore .confirmation .order-info .order-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.dkstore .confirmation .order-info .order-item-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dkstore .confirmation .order-info .order-item {
  background-color: #f9f9f9;
  margin-top: 10px;
  border-radius: 5px;
}
.dkstore .confirmation .order-info .order-item > div {
  padding: 10px 0;
}
.dkstore .confirmation .order-info .product-name {
  flex: 1;
}
.dkstore .confirmation .order-info .product-image {
  width: 30%;
}
.dkstore .confirmation .order-info .product-quantity {
  width: 10%;
  text-align: center;
}
.dkstore .confirmation .order-info .order-item .product-name,
.dkstore .confirmation .order-info .order-item .product-quantity {
  margin: auto;
}
.dkstore .confirmation .order-info .order-item .product-image {
  display: flex;
  justify-content: center;
}
.dkstore .confirmation .order-info .order-item .product-image img {
  max-width: 90%;
  max-height: 80px;
}
.dkstore .confirmation .action-btns {
  padding-top: 30px;
}
.dkstore .confirmation .action-btns > div {
  text-align: right;
}
.dkstore {
  border-radius: 10px;
}
.dkstore .spinner {
  align-self: center;
}
.dkstore .processing {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
}
.dkstore .complete {
  display: none;
  align-self: center;
}
.dkstore .complete .icon {
  font-size: 6em;
  color: green;
}
