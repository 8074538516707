.dkstore {

  .cart-quantity {
    position: absolute;
    top: 0;
    right: 0;
    background-color: orange;
    height: 2em;
    width: 2em;
    text-align: center;
    border-radius: 50%;
    padding-top: 5px;
  }

  .cart-redirect {
    color: black;
    :hover {
      color: grey;
    }
  }

  .product-list {
    padding: 10px;
    border-radius: 5px;
    background-color: white;

    .tax-wrapper {
      display: flex;
      gap: 5px;
    }

    .show-tax {
      margin: 3px 0 0!important;
    }

    .product-list-header {
      font-weight: 600;
    }

    .product-list-item, .product-list-header {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      > div {
        padding: 10px 5px;
      }
    }

    .product-list-item {
      background-color: #f9f9f9;
      border-radius: 5px;
      margin-top: 15px;
      padding: 5px 0;
    }

    .product-list-main {
      min-height: 20vh;
      padding-bottom: 50px;
    }

    .item, .header-item {
      display: flex;
      align-items: center;
    }

    .items {
      display: flex;
      flex-direction: column;
    }

    .product-price {
      width: 15%;
      justify-content: flex-end;
    }

    .product-image {
      display: flex;
      justify-content: center;
      width: 15%;

      img {
        max-width: 90%;
        max-height: 80px;
      }
    }

    .item-action {
      min-width: 30%;
      display: flex;
      justify-content: flex-end;
    }

    .product-name {
      flex: 1;
    }

    .action-btns {
      display: flex;
      justify-content: flex-end;
      padding: 10px 5px;

      .submit-btn {
        min-width: 150px;
      }
    }

    .add-button {
      min-width: 150px;
      padding: 5px;
    }

    .added {
      background-color: lightgreen;
    }
  }
}
