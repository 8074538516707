.dkstore {
  border-radius: 10px;
  .confirmation {
    width: 100%;
    min-height: 40vh;

    .order-text {
      padding: 20px 0;
    }

    .order-info {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      border-radius: 10px;

      .order-info-table {
        padding-top: 20px;
      }

      .order-info-header, .order-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .order-item-main {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .order-item {
        background-color: #f9f9f9;
        margin-top: 10px;
        border-radius: 5px;

        > div {
          padding: 10px 0;
        }
      }

      .product-name {
        flex: 1;
      }

      .product-image {
        width: 30%;
      }

      .product-quantity {
        width: 10%;
        text-align: center;
      }

      .order-item {

        .product-name, .product-quantity {
          margin: auto;
        }

        .product-image {
          display: flex;
          justify-content: center;

          img {
            max-width: 90%;
            max-height: 80px;
          }
        }
      }
    }

    .action-btns {
      padding-top: 30px;

      > div {
        text-align: right;
      }
    }
  }
}
