.dkstore {
  border-radius: 10px;

  .spinner {
    align-self: center;
  }

  .processing {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 40vh;
  }

  .complete {
    display: none;
    align-self: center;

    .icon {
      font-size: 6em;
      color: green;
    }
  }
}
